"use strict";

/**
 * If you wish to import a new JS library, you
 * should add it to the project via "npm install"
 * and then import it below.
 */

// node_modules imports.
//

// Vendor imports.
import './vendor/picturefill';
import './vendor/lazysizes';
import './vendor/skip-link-focus-fix';

// Project "IIFE" helper imports.
import './helpers/CookieNotification';

// Initial setup.
window.onload = () => {

	const locoScroll = new LocomotiveScroll({
		el: document.querySelector('.smooth-scroll'),
		smooth: true,
		reloadOnContextChange: true,
		offset: [100, 0],
	});

	// Default ScrollTrigger Settings
	gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
	document.addEventListener('lazyloaded', function(e){
		ScrollTrigger.refresh();
		// locoScroll.update();
	});

	// document.addEventListener("DOMContentLoaded", function(event){
	// 	locoScroll.update();
	// });

	// setTimeout(function(){
	// 	locoScroll.update();
	// }, 1000);

	locoScroll.on("scroll", ScrollTrigger.update);

	ScrollTrigger.scrollerProxy(".smooth-scroll", {
		scrollTop(value) {
		  return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
		}, // we don't have to define a scrollLeft because we're only scrolling vertically.
		getBoundingClientRect() {
		  return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
		},
		pinType: document.querySelector(".smooth-scroll").style.transform ? "transform" : "fixed"
	});

	let footerFlag = false;

	locoScroll.on('scroll', ({ limit, scroll }) => {
		const progress = parseInt(scroll.y / limit.y * 100)
		if (!footerFlag && progress >= 95) {
			locoScroll.update();
			footerFlag = true;
		}
	})

	let carousel = document.querySelector(".swiper");

	if (carousel !== null) {
		const swiper = new Swiper('.swiper', {
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			preloadImages: false,
			lazy: {
				loadOnTransitionStart: true,
			},
			speed: 800,
			autoplay: {
				delay: 5000,
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
		});

		let carouselSlides = document.querySelectorAll(".swiper-slide");

		swiper.on('beforeTransitionStart', function () {
			let currentIndex = swiper.realIndex - 1;
			let currentSlide =  document.querySelector("[data-swiper-slide-index='" + currentIndex + "']");

			carouselSlides.forEach(slide =>  slide.classList.remove("active"));
			currentSlide.classList.add("active");
		});
	}


	const projectSwiper = new Swiper('.block-projects-carousel__carousel', {
		// Optional parameters
		loop: true,
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		// preloadImages: false,
		// lazy: true,
		speed: 300,
		pagination: {
			el: '.block-projects-carousel__pagination',
			type: 'bullets',
			clickable: true,
		},
		navigation: {
			nextEl: '.block-projects-carousel__arrow--next',
			prevEl: '.block-projects-carousel__arrow--prev',
		},
	});



	// SEARCH TRIGGER
	let searchTrigger = document.querySelector(".site-header__button--search");
	let searchPanel = document.querySelector(".site-header__search-panel");

	if (searchTrigger !== null) {
		searchTrigger.addEventListener("click", function(){
			this.classList.toggle("active");
			searchPanel.classList.toggle("active");
		}, true);
	}

	// MOBILE MENU TRIGGER
	let mobileMenuTrigger = document.querySelector(".site-header__button--hamburger");

	if (mobileMenuTrigger !== null) {
		mobileMenuTrigger.addEventListener("click", function(){
			this.classList.toggle("active");
		}, true);
	}

	// MEGA MENU CALCULATE
	// function megaMenuCalculate() {

	// 	if (window.innerWidth > 1200) {

	// 		let megaMenus = document.querySelectorAll('.mega-menu');

	// 		if (megaMenus !== null) {

	// 			megaMenus.forEach((megaMenu) => {

	// 				setTimeout(function(){
	// 					let firstMenu = megaMenu.getElementsByClassName('sub-menu')[0];
	// 					firstMenu.style.width = (100 / 3) + "%";
	// 					let menuWidth = firstMenu.offsetWidth;

	// 					let childMenus = megaMenu.querySelectorAll(".sub-menu");

	// 					childMenus.forEach((menu) => {
	// 						menu.style.width = menuWidth + "px";

	// 						let parentMenu = menu.closest(".sub-menu-container");

	// 						if (parentMenu !== null) {
	// 							let parentMenuRect = parentMenu.getBoundingClientRect();
	// 							let rightPosition = parentMenuRect.right;

	// 							menu.style.left = rightPosition + "px";
	// 						}
	// 					});
	// 				}, 100);
	// 			});
	// 		}
	// 	}
	// }

	// megaMenuCalculate();

	// window.addEventListener('resize', function(event){
	// 	megaMenuCalculate();
	// });

	// NAVIGATION HOVER INTENT
	function activeList(){
		$(this).addClass("js-hover");
	}

	function deactiveList() {
		$(this).removeClass("js-hover");
	}

	function hoverIntentNav() {
		if (window.innerWidth >= 1200) {
			$("#site-navigation").hoverIntent({
				over: activeList,
				out: deactiveList,
				selector: '.menu-item',
				timeout: 300,
			});
		} else {
			$("#site-navigation").unbind("mouseenter").unbind("mouseleave");
			$("#site-navigation").removeProp('hoverIntent_t');
			$("#site-navigation").removeProp('hoverIntent_s');
		}
	}

	hoverIntentNav();

	window.addEventListener('resize', function(event){
		hoverIntentNav();
	});


	// MENU EXPAND TOGGLE
	var menuToggle = document.querySelector(".menu-toggle");
	var menu = document.querySelector("#site-navigation");
	var header = document.querySelector(".site-header");

	if (menuToggle !== null && menu !== null) {
		menuToggle.addEventListener("click", menuExpand);

		function menuExpand() {
			if (this.classList.contains("--expand")) {
				this.classList.remove("--expand");
			} else {
				this.classList.add("--expand");
			}

			if (menu.classList.contains("--expand")) {
				menu.classList.remove("--expand");
			} else {
				menu.classList.add("--expand");
			}

			if (header.classList.contains("--expand")) {
				header.classList.remove("--expand");
			} else {
				header.classList.add("--expand");
			}
		}
	}

	function revealMenuMobile(e) {
		if (window.innerWidth < 1200) {
			e.stopPropagation();
			if (!e.target.classList.contains('sub-menu-container__back')) {
				var parentDropdown = e.target.closest(".menu-item-has-children");
				parentDropdown.classList.add("js-hover");
			}
		}
	}


	var dropdownLinks = document.querySelectorAll(".menu-item-has-children");

	function resizeLinks() {
		for (var i = 0; i < dropdownLinks.length; ++i) {
			var dropdownLink = dropdownLinks[i];

			if (window.innerWidth < 1200) {
				dropdownLink.addEventListener("click", function(e) {revealMenuMobile(e)});
			} else {
				// dropdownLink.addEventListener("mouseover", function(e) {revealMenuDesktop(e)});
				// dropdownLink.addEventListener("mouseleave", closeMenu);
			}
		}
	}

	resizeLinks();
	window.addEventListener("resize", resizeLinks);

	// BACK LINKS FOR MENU ON MOBILE
	var backLinks = document.querySelectorAll(".sub-menu-container__back");

	function findAndCloseMenu() {
		if (window.innerWidth < 1200) {
			var parent = this.closest(".js-hover");
			parent.classList.remove("js-hover");
		}
	}

	for (var i = 0; i < backLinks.length; ++i) {
		var backLink = backLinks[i];
		backLink.addEventListener("click", findAndCloseMenu);
	}


	// FEATURED LIST EQUAL HEIGHT
	let featuredLists = document.querySelectorAll(".block-featured-list");

	if (featuredLists !== null) {
		function listItemHeights() {
			let heights = [];

			featuredLists.forEach((list) => {

				let listItems = list.querySelectorAll(".block-featured-list__list-item-link");

				if (listItems !== null) {
					listItems.forEach((item) => {
						item.style.height = "auto";
						heights.push(item.offsetHeight);
					});

					let tallest = Math.max.apply(Math, heights);

					listItems.forEach((item) => {
						item.style.height = tallest + "px";
					});
				}

			});
		}

		listItemHeights();

		window.addEventListener('resize', function(event){
			listItemHeights();
		});
	}

	// const showAnim = gsap.from('.site-header', {
	// 	backgroundColor: '#001738',
	// 	paused: true,
	// 	duration: 0.3
	// }).progress(1);

	// ScrollTrigger.create({
	// 	start: "top top",
	// 	end: 99999,
	// 	onUpdate: (self) => {
	// 		self.direction === -1 ? showAnim.play();
	// 		if (se)
	// 	}
	// });

	// let pageHeader = document.querySelector(".site-header");

	// var anim_header = gsap.timeline({
	// 	scrollTrigger: {
	// 		start: 'top top',
	// 		end: '+=' + pageHeader.offsetHeight * 1.75 + 'px'
	// 	}
	// });

	// anim_header
	// .to(pageHeader, {
	// 	opacity: 0,
	// 	padding: '1em'
	// });

	// var headerPadding;

	// if (window.innerWidth < 1200) {
	// 	headerPadding = 20;
	// } else {
	// 	headerPadding = 50;
	// }

	// var headerTimeline = gsap.timeline();
	// var headerScroll = headerTimeline.fromTo(".site-header",
	// {
	// 	backgroundColor: 'transparent',
	// 	paddingTop: headerPadding + 'px',
	// 	paddingBottom: headerPadding + 'px',
	// },
	// {
	// 	backgroundColor: "#001738",
	// 	paddingTop: '20px',
	// 	paddingBottom: '20px',
	// 	duration: 0.3,
	// });

	// ScrollTrigger.create({
	// 	start: "top top",
	// 	end: 9999999999999,
	// 	scroller: ".smooth-scroll",
	// 	// scrub: true,
	// 	// animation: headerScroll,
	// 	// toggleActions: "play none none reverse",
	// 	toggleClass: {targets: '.site-header', className: "is-active"},
	// 	markers: true,
	// });

	locoScroll.on("scroll", (position) => {
		if ((position.scroll.y) > 50) {
		  document.querySelector("#js-header").classList.add("is-active");
		  document.querySelector("body").classList.add("active-header");
		} else {
		  document.querySelector("#js-header").classList.remove("is-active");
		  document.querySelector("body").classList.remove("active-header");
		}
	});


	// HANDBOOK ACCORDION
	const accordionContainer = document.querySelector(".block-item-accordion");

	if (accordionContainer !== null) {

		let activeItem = accordionContainer.querySelector(".is-open");

		if (activeItem !== null) {
			let activeContent = activeItem.querySelector(".block-item-accordion__sub-content");
			let activeContentInner = activeContent.children[0];
			let height = activeContentInner.getBoundingClientRect().height;

			activeContent.style.height = `${height}px`;
		}

		accordionContainer.addEventListener("click", (e) => {
			const accordionHeader = e.target.closest(".block-item-accordion__item-panel");

			if (accordionHeader) {
				const accordion = accordionHeader.closest("li");
				const accordionContent = accordionHeader.closest(".block-item-accordion__item-panel").nextElementSibling;
				const accordionInner = accordionContent.children[0];

				let height;

				if (accordion.classList.contains("is-open")) {
					height = 0;
				} else {
					height = accordionInner.getBoundingClientRect().height;
				}

				var allPanels = accordionContainer.querySelectorAll(".js-expand:not(.is-open)");
				var allActivePanels = accordionContainer.querySelectorAll(".is-open");

				if (!accordion.classList.contains("mapbox-load") && accordionContainer.classList.contains("block-item-accordion--office")) {

					let mapBlock = accordion.querySelector(".e-contact-block__map");
					console.log(mapBlock);

					let id = mapBlock.id;
					let lng = mapBlock.getAttribute('data-lng');
					let lat = mapBlock.getAttribute('data-lat');
					let coOrds = [lng, lat];

					var map = new mapboxgl.Map({
						container: id,
						center: coOrds,
						minzoom: 10,
						zoom: 14,
						style: 'mapbox://styles/mapbox/streets-v11?optimize=true'
					});

					var el = document.createElement('div');
					el.className = 'marker';

					// make a marker for each feature and add to the map
					new mapboxgl.Marker(el)
					.setLngLat(coOrds)
					.setPopup(
						new mapboxgl.Popup({ offset: 25 }) // add popups
					)
					.addTo(map);
				}

				accordion.classList.add("mapbox-load");


				allPanels.forEach((panel) => {
					panel.classList.remove("is-open");
					panel.querySelector(".block-item-accordion__sub-content").style.height = 0;
				});

				accordion.classList.toggle("is-open");
				accordionContent.style.height = `${height}px`;

				allActivePanels.forEach((panel) => {
					panel.classList.remove("is-open");
					panel.querySelector(".block-item-accordion__sub-content").style.height = 0;
				});

			}
		});
	}


	// STICKY COLUMNS
	let stickyElements = document.querySelectorAll(".js-sticky");

	if (stickyElements !== null) {
		stickyElements.forEach(function(el) {

			let stickyColumn = el.closest(".js-sticky-column");

			ScrollTrigger.matchMedia({
				"(min-width: 1200px)": function() {
					ScrollTrigger.create({
						trigger: stickyColumn,
						pin: el,
						start: "top 15%",
						end: "bottom 90%",
						scroller: ".smooth-scroll",
						scrub: true,
					});
				},
			});

		});
	}

	const stickyNormal = document.querySelectorAll(".js-sticky-normal");

	if (stickyNormal !== null) {
		stickyNormal.forEach(function(el) {

			let stickyColumn = el.closest(".js-sticky-column");

			ScrollTrigger.matchMedia({
				"(min-width: 1200px)": function() {
					ScrollTrigger.create({
						trigger: stickyColumn,
						pin: el,
						start: "top 5%",
						end: "bottom 60%",
						scroller: ".smooth-scroll",
						scrub: true,
					});
				},
			});

		});
	}


	let dropdownMenus = document.querySelectorAll(".e-filters__select-dropdown");

	dropdownMenus.forEach((menu) => {
		menu.addEventListener("mouseover", function(){
			locoScroll.stop();
		}, true);
		menu.addEventListener("mouseout", function(){
			locoScroll.start();
		}, true);
	});


	const teamGrid = document.querySelector(".block-team-grid");
	const projectGrid = document.querySelector(".block-project-grid");
	const knowledgeHubGrid = document.querySelector(".block-knowledge-hub-grid");
	const handbookGrid = document.querySelector(".block-handbook-grid");

	if (handbookGrid !== null) {
		let paginationLinks = handbookGrid.querySelectorAll(".e-pagination a");

		if (paginationLinks !== null) {

			function sendPageNoHandbook(link) {
				let cleanedUrl = link.replace(/\/$/, '');
				let pageNo = cleanedUrl.split('/').pop();
				refreshHandbook(pageNo);
			}

			function refreshHandbook(pageNo) {
				$.ajax({
					url: ajax.ajaxurl,
					type: 'POST',
					data: {
						action: 'handbook_grid',
						paged: pageNo || 1,
					},
					beforeSend: function (xhr) {
						$(".block-handbook-grid__container").css("opacity", "0.5");
					},
				}).done(function (data) {
					$(".block-handbook-grid__container").css("opacity", "1");
					$(".block-handbook-grid__container").empty();
					$(".block-handbook-grid__container").append(data);
					locoScroll.update();
				});
			}

			$(".block-handbook-grid").on('click','.e-pagination a', function(e) {
				e.preventDefault();
				var thisHref = $(this).attr('href');
				sendPageNoHandbook(thisHref);
			});
		}
	}

	if (knowledgeHubGrid !== null) {
		let paginationLinks = knowledgeHubGrid.querySelectorAll(".e-pagination a");
		var types = [];
		var categories = [];
		var tags = [];
		var checkboxes = [];

		$('.e-filters__item input[type=checkbox]').change(function() {
			types = [];
			categories = [];
			tags = [];
			checkboxes = [];

			$('.e-filters__item input[type=checkbox]:checked').each(function () {
				var thisCheckboxValue = $(this).val();
				var thisCheckboxName = $(this).attr("name");

				if (thisCheckboxName == 'related_sectors[]' || thisCheckboxName == 'related_services[]') {
					checkboxes.push({
						'name': thisCheckboxName,
						'value': thisCheckboxValue,
					});
				} else if (thisCheckboxName == 'categories[]') {
					categories.push({
						'name': thisCheckboxName,
						'value': thisCheckboxValue,
					});
				} else if (thisCheckboxName == 'tags[]') {
					tags.push({
						'name': thisCheckboxName,
						'value': thisCheckboxValue,
					});
				} else {
					types.push({
						'name': thisCheckboxName,
						'value': thisCheckboxValue,
					});
				}

				console.log(tags);
			});

			$(".e-filters__group").removeClass("active");
			refreshKnowledgeHub(1, checkboxes);
		});

		function refreshKnowledgeHub(pageNo) {
			$.ajax({
				url: ajax.ajaxurl,
				type: 'POST',
				data: {
					action: 'knowledge_hub_grid',
					types: types,
					categories: categories,
					tags: tags,
					checkboxes: checkboxes,
					paged: pageNo || 1,
				},
				beforeSend: function (xhr) {
					$(".block-knowledge-hub-grid__container").css("opacity", "0.5");
				},
			}).done(function (data) {
				$(".block-knowledge-hub-grid__container").css("opacity", "1");
				$(".block-knowledge-hub-grid__container").empty();
				$(".block-knowledge-hub-grid__container").append(data);
				locoScroll.update();
			});
		}

		if (paginationLinks !== null) {

			function sendPageNoKnowledgeHub(link) {
				let cleanedUrl = link.replace(/\/$/, '');
				let pageNo = cleanedUrl.split('/').pop();
				refreshKnowledgeHub(pageNo);
			}

			$(".block-knowledge-hub-grid").on('click','.e-pagination a', function(e) {
				e.preventDefault();
				var thisHref = $(this).attr('href');
				sendPageNoKnowledgeHub(thisHref);
			});
		}
	}

	if (teamGrid !== null) {
		let paginationLinks = teamGrid.querySelectorAll(".e-pagination a");

		if (paginationLinks !== null) {

			function sendPageNo(link) {
				let cleanedUrl = link.replace(/\/$/, '');
				let pageNo = cleanedUrl.split('/').pop();
				refreshTeam(pageNo);
			}

			function refreshTeam(pageNo) {
				$.ajax({
					url: ajax.ajaxurl,
					type: 'POST',
					data: {
						action: 'team_grid',
						paged: pageNo || 1,
					},
					beforeSend: function (xhr) {
						$(".block-team-grid").css("opacity", "0.5");
					},
				}).done(function (data) {
					$(".block-team-grid").css("opacity", "1");
					$(".block-team-grid").empty();
					$(".block-team-grid").append(data);
					locoScroll.update();
				});
			}

			$(".block-team-grid").on('click','.e-pagination a', function(e) {
				e.preventDefault();
				var thisHref = $(this).attr('href');
				sendPageNo(thisHref);
			});
		}
	}

	if (projectGrid !== null) {

		let paginationLinks = projectGrid.querySelectorAll(".e-pagination a");
		var checkboxes = [];

		$('.e-filters__item input[type=checkbox]').change(function() {
			checkboxes = [];

			$('.e-filters__item input[type=checkbox]:checked').each(function () {
				var thisCheckboxValue = $(this).val();
				var thisCheckboxName = $(this).attr("name");

				checkboxes.push({
					'name': thisCheckboxName,
					'value': thisCheckboxValue,
				});
			});

			$(".e-filters__group").removeClass("active");
			refreshProject(1, checkboxes);
		});

		function refreshProject(pageNo, checkboxes = null) {
			$.ajax({
				url: ajax.ajaxurl,
				type: 'POST',
				data: {
					action: 'project_grid',
					checkboxes: checkboxes,
					paged: pageNo || 1,
				},
				beforeSend: function (xhr) {
					$(".block-project-grid__container").css("opacity", "0.5");
				},
			}).done(function (data) {
				$(".block-project-grid__container").css("opacity", "1");
				$(".block-project-grid__container").empty();
				$(".block-project-grid__container").append(data);
				locoScroll.update();
			});
		}

		if (paginationLinks !== null) {

			function sendPageNoProject(link) {
				let cleanedUrl = link.replace(/\/$/, '');
				let pageNo = cleanedUrl.split('/').pop();
				refreshProject(pageNo);
			}

			$(".block-project-grid").on('click','.e-pagination a', function(e) {
				e.preventDefault();
				var thisHref = $(this).attr('href');
				sendPageNoProject(thisHref);
			});
		}
	}

	var maps = document.querySelectorAll('.e-contact-block__map--solo');
	var accordionMaps = document.querySelectorAll('.block-item-accordion .e-contact-block__map');

	if (maps !== null || accordionMaps !== null) {
		mapboxgl.accessToken = 'pk.eyJ1Ijoic3RyYXRlZ2lxIiwiYSI6ImNqc2trb252NzEyZjYzenBvYTRhbmdyNDEifQ.la_5DQo_qJaALM-bp_sc2w';
	}

	// SOLO MAPBOX MAPS

	if (maps !== null) {

		maps.forEach(function(mapBlock) {

			let id = mapBlock.id;
			let lng = mapBlock.getAttribute('data-lng');
			let lat = mapBlock.getAttribute('data-lat');
			let coOrds = [lng, lat];

			var map = new mapboxgl.Map({
				container: id,
				center: coOrds,
				minzoom: 10,
				zoom: 14,
				style: 'mapbox://styles/mapbox/streets-v11?optimize=true'
			});

			var el = document.createElement('div');
			el.className = 'marker';

			// make a marker for each feature and add to the map
			new mapboxgl.Marker(el)
			.setLngLat(coOrds)
			.setPopup(
				new mapboxgl.Popup({ offset: 25 }) // add popups
			)
			.addTo(map);
		});
	}


	let filters = document.querySelectorAll(".e-filters__group");

	if (filters !== null) {
		filters.forEach((filter) => {

			let filterSelect = filter.querySelector(".e-filters__select");

			filterSelect.addEventListener("click", function(){
				if (this.closest(".e-filters__group").classList.contains("active")) {
					filters.forEach((filter) => {
						filter.classList.remove("active");
					});
					return;
				} else {
					filters.forEach((filter) => {
						filter.classList.remove("active");
					});
					this.closest(".e-filters__group").classList.toggle("active");
				}
			}, true);
		});
	}

	// ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
	// ScrollTrigger.refresh();

	let animatedHero = document.querySelector(".block-animated-hero");

	if (animatedHero !== null) {

		function updateObjectSizes() {

			let animatedHeroObjects = animatedHero.querySelectorAll(".block-animated-hero__animal");

			if (window.innerWidth < 992) {
				animatedHeroObjects.forEach((heroObject) => {
					let objectPicture = heroObject.querySelector("picture");
					let mobileWidth = heroObject.getAttribute("data-mobile-size");
					objectPicture.style.width = mobileWidth + "px";
					objectPicture.style.bottom = "50%";
				});
			} else {
				animatedHeroObjects.forEach((heroObject) => {
					let objectPicture = heroObject.querySelector("picture");
					let desktopWidth = heroObject.getAttribute("data-desktop-size");
					let desktopPosition = heroObject.getAttribute("data-desktop-position");
					objectPicture.style.width = desktopWidth + "px";
					objectPicture.style.bottom = desktopPosition + "%";
				});
			}
		}

		updateObjectSizes();

		window.addEventListener('resize', function(event){
			updateObjectSizes();
		});
	}


	// function inViewport($el) {
	// 	var elH = $el.outerHeight(),
	// 		H   = $(window).height(),
	// 		r   = $el[0].getBoundingClientRect(), t=r.top, b=r.bottom;
	// 	return Math.max(0, t>0? Math.min(elH, H-t) : Math.min(b, H));
	// }

	// setTimeout(function(){
	// 	$(".sub-menu").each(function( index ) {
	// 		$(this).css("height", inViewport($(this)) + 'px');
	// 	});
	// }, 500);


	// TOGGLE MARKETING OPTIN BOX FOR NETSUITE INTEGRATION
	const cf7Forms = document.querySelectorAll(".wpcf7-form");

	if (cf7Forms !== null) {
		cf7Forms.forEach((form) => {
			let defaultMarketingField = form.querySelector('input[name=marketing]');
			let netsuiteMarketingField = form.querySelector('input[name=marketing-optin]');

			if (defaultMarketingField !== null && netsuiteMarketingField !== null) {
				defaultMarketingField.addEventListener('change', function() {
					if (this.checked) {
						netsuiteMarketingField.value = 'TRUE';
					} else {
						netsuiteMarketingField.value = 'FALSE';
					}
				  });
			}
		});
	}


	// OPEN IMAGES IN LITY LIGHTBOX IN ENTRY CONTENT CONTAINERS
	$(document).on('click', '.entry-content__container img', lity);

}

